import React, { useState } from "react"
import { Grid, MenuItem, Box, makeStyles, Snackbar } from "@material-ui/core"
import { object, string } from "yup"
import { useTranslation } from "react-i18next"
import { FormRenderProps } from "react-final-form"
import axios from "axios"

import { Form } from "./final-form/form"
import { Field } from "./final-form/field"
import { TextField } from "components/text-field"
import { SelectField } from "./select-field"
import { ProgressButton } from "./progress-button"
import API_ENDPOINTS from "../constants/api-endpoints"
import { getEnvURL } from "../utilities"
import { useQueryVisibleCities } from "./admin/common/city-selector"

const useStyles = makeStyles({
  textArea: {
    minHeight: 200,
  },
})

interface FormValues {
  name: string
  city: string
  email: string
  message: string
}

export const ContactForm = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [toastMessage, setToastMessage] = useState()
  const { data: cityData } = useQueryVisibleCities()
  const citiesOptions =
    cityData &&
    cityData.cities.map(({ id, name }) => (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    ))
  const schema = object().shape({
    name: string().required(),
    city: string().required(),
    email: string()
      .email()
      .required(),
    message: string().required(),
  })

  const handleSubmit = async (data: FormValues, actions) => {
    const { name, email, message, city } = data
    return axios
      .post(getEnvURL(API_ENDPOINTS.CONTACT), {
        name,
        email,
        city,
        message,
      })
      .then(() => {
        setTimeout(actions.reset)
        setToastMessage(t("contacts.message-sent"))
        return undefined
      })
      .catch(e => {
        setToastMessage(t("contacts.message-sending-failed"))
        return {}
      })
  }

  const FormTemplate = (props: FormRenderProps<FormValues>) => {
    const { handleSubmit, submitting, hasValidationErrors } = props
    return (
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              name="name"
              label={t("contacts.form.name")}
              component={TextField}
              disabled={submitting}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="email"
              type="email"
              label={t("contacts.form.email")}
              component={TextField}
              disabled={submitting}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="city"
              label={t("contacts.form.city")}
              component={SelectField}
              disabled={submitting}
              fullWidth
              required
            >
              {citiesOptions}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="message"
              label={t("contacts.form.message")}
              InputProps={{
                className: classes.textArea,
                rows: 7,
              }}
              component={TextField}
              disabled={submitting}
              multiline
              fullWidth
              required
            />
          </Grid>
          <Grid item container justifyContent="center">
            <Box mt={1}>
              <ProgressButton
                variant="outlined"
                color="secondary"
                type="submit"
                size="large"
                progress={submitting}
                disabled={submitting || hasValidationErrors}
              >
                {t("contacts.form.submit")}
              </ProgressButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    )
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        open={!!toastMessage}
        message={toastMessage}
        onClose={() => {
          setToastMessage("")
        }}
      />
      <Form
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={{ name: "", email: "", city: "", message: "" }}
        render={FormTemplate}
      />
    </>
  )
}
